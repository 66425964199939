.modules {
    grid-area: navigator;
    display: none;
    justify-content: space-around;
    flex-direction: column;
    align-items: left;
    flex-wrap: wrap;

    position: absolute;
    left: -100%;
    height: 100vh;
    /*width: 30%;*/
    /*transition: all .5s;*/
    border-right: white solid 2px;
}

.line {
    width: 30px;
    height: 3px;
    margin: 5px;
    background: white;
  }

  .active {
    display: flex;
    left:0;
    /*transition: all .5s;*/
}


.subModules {
  grid-area: navigator;
  display: none;
  justify-content: space-around;
  flex-direction: column;
  align-items: left;
  flex-wrap: wrap;

  position: absolute;
  right: -100%;
  height: 100vh;
  /*width: 30%;*/
  /*transition: all .5s;*/
  border-left: white solid 2px;
}

.activeSub {
  display: flex;
  right: 0;
  /*transition: all .5s;*/
}

