.card {
    font-family: 'Architects Daughter', cursive;
    text-align: center;
    grid-area: card;   
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow-y: auto;
}

.front {
}

.back {
}