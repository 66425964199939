ul {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

li {
	display: inline;
	font-family: 'Permanent Marker', cursive;
}

.home {
	text-align: center;
}
