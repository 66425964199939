.bg {
    /* The image used */
    background-image: url("error.gif");
  
    /* Full height */
    height: 95vh;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    text-align: left;
    color: black;
    
  }