.title {
    display: flex;
    justify-content: space-between;
    grid-area: title;
}

img {
    border-radius: 90px;
    height: 100px;
    padding: 5px;
}

.title-block h1 {
    display: inline-block;
}

.subtitle {
    display: inline-block;
    font-family: 'Permanent Marker', cursive;
    margin-left: 10px;
}

/*
.subModules-jelly {
    grid-area: navigator;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: left;
    flex-wrap: wrap;
  
    position: absolute;
    right: -100%;
    height: 100vh;
    width: 30%;
    transition: all .5s;
    border-left: white solid 2px;
  }
  
  .activeSub-jelly {
    right: 0;
    transition: all .5s;
  }
  */