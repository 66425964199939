* {
    box-sizing: border-box;
    background-color: black;
    color: beige;
}

.main-container {
    display: grid;
    width: 100%;
    height: 100vh;
    position: relative;
    margin: auto;
    grid-template-areas:
    "navigator" 
    "title"
    "card"
    "bottom";
    /*grid-template-rows: 20px 115px 3fr 1fr;*/
    grid-template-rows: 20px .8fr 3fr 1fr;
}

h1, h3 {
    font-family: 'Permanent Marker', cursive;
}

.bottom {
 grid-area: bottom;   
}

.arrow-container {
    display: flex;
    justify-content: space-around;
    align-content: center;
    font-weight: bold;
    font-size: xx-large;
}

#left, #right {
    width: 100%;
    text-align: center;
}

.arrow {
    border-radius: 20px;
    border: white solid 2px;
    height: 105px;
    margin: 5px;
}

